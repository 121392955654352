import React, { Fragment } from 'react'
import { css } from '@emotion/react'

import {
  common_space,
  media_mobile,
  post_card_grid_template_columns,
} from '../../style/common_value'

import TaxonomyAllPostList from '../molecules/TaxonomyBlogPostList/TaxonomyAllPostList'
import TaxonomyMasterCategoryList from '../molecules/TaxonomyBlogPostList/TaxonomyMasterCategoryList'
import TaxonomyCategoryList from '../molecules/TaxonomyBlogPostList/TaxonomyCategoryList'
import TaxonomyTagList from '../molecules/TaxonomyBlogPostList/TaxonomyTagList'

const taxonomy_style = css({
  margin: `${common_space.l} ${common_space.s} ${common_space.l}`,
  [media_mobile.sp]: {
    margin: `${common_space.l} 0 ${common_space.l}`,
  },
  '.taxonomy_title': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.8rem',
    marginBottom: common_space.m,
    paddingTop: '1.5rem',
    [media_mobile.tab]: {
      marginBottom: common_space.l,
      paddingTop: '0',
    },
  },
  '.post_card_list': {
    display: 'grid',
    gap: '2rem 1rem',
    gridTemplateColumns: post_card_grid_template_columns,
    justifyContent: 'space-evenly',
    [media_mobile.tab]: {
      gap: '4rem 1rem',
    },
  },
})

type props_type = {
  pageContext: {
    slug: string
    content_type: 'All' | 'MasterCategory' | 'Category' | 'Tag'
  }
}

export const Taxonomy: React.FC<props_type> = ({ pageContext }) => {
  let result: JSX.Element
  switch (pageContext.content_type) {
    case 'All':
      result = <TaxonomyAllPostList enable_metadata />
      break
    case 'MasterCategory':
      result = (
        <TaxonomyMasterCategoryList
          target_slug={pageContext.slug}
          enable_metadata
        />
      )
      break
    case 'Category':
      result = (
        <TaxonomyCategoryList target_slug={pageContext.slug} enable_metadata />
      )
      break
    case 'Tag':
      result = (
        <TaxonomyTagList target_slug={pageContext.slug} enable_metadata />
      )
      break
    default:
      result = <Fragment />
  }
  return (
    <>
      <div css={taxonomy_style}>{result}</div>
    </>
  )
}

export default Taxonomy
