import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { MoleculesTaxonomyTagListQuery } from '../../../../types/graphql-types'

import { blog_post_core } from './blog_post_core'
import {
  TaxonomyMetadata,
  input_meta_data_type,
} from '../../metadata/TaxonomyMetadata'

type props_type = {
  target_slug: string
  pickup_number?: number
  enable_metadata?: boolean
}

export const TaxonomyTagList: React.FC<props_type> = ({
  target_slug,
  pickup_number,
  enable_metadata = false,
}) => {
  const query_data: MoleculesTaxonomyTagListQuery = useStaticQuery(graphql`
    query MoleculesTaxonomyTagList {
      allContentfulTag(
        limit: 1000
        sort: { fields: blog_post___published_date, order: DESC }
      ) {
        edges {
          node {
            name
            id
            slug
            blog_post {
              title
              id
              slug
              published_date
              modified_date
              category {
                name
                slug
                master_category {
                  name
                  slug
                }
                thumbnail {
                  title
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              thumbnail {
                title
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)
  const result: (JSX.Element | JSX.Element[])[] = []
  for (const i of query_data.allContentfulTag.edges) {
    if (i.node.slug !== target_slug) {
      // eslint-disable-next-line no-continue
      continue
    }
    if (!i.node.blog_post) {
      break
    }
    const meta_data: input_meta_data_type = {
      tag_name: i.node.name,
      tag_slug: i.node.slug,
      published_date: i.node.blog_post
        ? i.node.blog_post[0].published_date
        : null,
      modified_date: i.node.blog_post
        ? i.node.blog_post[0].modified_date
        : null,
    }
    result.push(
      enable_metadata && (
        <TaxonomyMetadata
          content_type="Tag"
          input_meta_data={meta_data}
          key={`TaxonomyMetadata_${i.node.id}`}
        />
      )
    )
    result.push(
      <div className="taxonomy_title" key={`TaxonomyTagList_${i.node.id}`}>
        {i.node.name}
      </div>
    )
    result.push(blog_post_core(i.node.blog_post, pickup_number))
    break
  }
  return <>{result}</>
}

export default TaxonomyTagList
